import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import useStyles from './Logs.styles';

interface FormProps {
  chargePointId: string;
}

export const Logs: React.FC<FormProps> = ({ chargePointId }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Tooltip title="Logs">
      <IconButton
        className={classes.logs}
        onClick={() => navigate(`/charger/${chargePointId}/logs`)}
      >
        <TextSnippetIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};
