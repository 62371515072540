import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Grid, TextField, Typography, Button, Box } from '@mui/material';

import { useWs } from 'store/ws';
import chargerImage from '../../assets/charging-station.png';
import { useMessage } from '../../store/message';
import useStyles from './Login.styles';

interface FormInput {
  username: string;
  password: string;
}

export const Login: React.FC = () => {
  const { register, handleSubmit } = useForm<FormInput>();
  const { isConnected } = useWs();
  const { setMessage } = useMessage();

  const classes = useStyles();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    console.log(data);
    window.location.reload();
  };

  React.useEffect(() => {
    if (isConnected) {
      navigate('/');
      setMessage({
        type: 'success',
        text: 'Successfully logged in',
      });
    }
  }, [isConnected, navigate, setMessage]);

  return (
    <>
      <Typography
        variant="h2"
        textAlign="center"
        fontWeight="bold"
      >
        Sign in
      </Typography>
      <Grid
        container
        alignItems="center"
        spacing={4}
        textAlign="center"
        marginTop={2}
      >
        <Grid
          item
          xs={6}
          lg={6}
        >
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.root}
          >
            <TextField
              type="text"
              label="Username"
              {...register('username', { required: true })}
              required
            />
            <TextField
              type="password"
              label="Password"
              {...register('password', { required: true })}
              required
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              className={classes.submitButton}
            >
              Login
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          lg={6}
        >
          <img
            src={chargerImage}
            alt="EV charger"
            width="300px"
          />
        </Grid>
      </Grid>
    </>
  );
};
