export const MuiTypography = {
  styleOverrides: {
    h1: {
      margin: '1rem 0',
      '@media (min-height:900px)': {
        margin: '2rem 0',
      },
    },
    h2: {
      margin: '1rem 0',
      '@media (min-height:900px)': {
        margin: '2rem 0',
      },
    },
    h3: {
      margin: '1rem 0',
      '@media (min-height:900px)': {
        margin: '2rem 0',
      },
    },
    h4: {
      margin: '5px 0',
      '@media (min-height:900px)': {
        margin: '10px 0',
      },
    },
  },
};
