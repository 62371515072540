import * as _ from 'lodash-es';

const appendPath = (...paths: (string | number)[]) => _.join(paths, '/');

const HOME = {
  getPath: (): string => appendPath('/'),
};

const LOGIN = {
  getPath: (): string => appendPath('/login'),
};

const DEBUG_CHARGER_PAGE = {
  getPath: (params: Record<'id', string>): string => appendPath(
    '/debug-charger',
    params.id,
  ),
};

const CHARGER_PAGE = {
  getPath: (params: Record<'id', string>): string => appendPath(
    '/charger',
    params.id,
  ),
};

const SETTINGS = {
  getPath: (params: Record<'id', string>): string => appendPath(
    '/charger',
    params.id,
    'settings',
  ),
};

const LOGS = {
  getPath: (params: Record<'id', string>): string => appendPath(
    '/charger',
    params.id,
    'logs',
  ),
};

const CHARGERS = {
  getPath: (): string => appendPath('/chargers'),
};

export const Routing = {
  HOME,
  LOGIN,
  DEBUG_CHARGER_PAGE,
  CHARGER_PAGE,
  SETTINGS,
  LOGS,
  CHARGERS,
};
