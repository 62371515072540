import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  submitButton: {
    margin: '20px auto !important',
    width: '30%',
  },
});

export default useStyles;
