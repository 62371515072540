import React, { useEffect } from 'react';
import ReactGA from 'react-gtm-module';
import { useCookies } from 'react-cookie';
import { Routes } from 'app/Routes';
import { Snackbar, Alert, Container, useMediaQuery } from '@mui/material';
import { CustomDrawer } from 'components/drawer/Drawer';
import { useWs } from 'store/ws';
import { Backdrop } from 'components/backdrop/backdrop';
import { useChargePoint } from 'store/chargePoint';
import { useAuth0 } from '@auth0/auth0-react';
import { useMessage } from './store/message';
import { config } from './config/config';
import UserFeedback from './components/user-feedback/UserFeedback';
import LoadingScreen from './components/loading-screen/loading-screen';

if (config.googleMesurementId) {
  ReactGA.initialize({ gtmId: config.googleMesurementId });
}

const App: React.FC = () => {
  const { isConnected } = useWs();
  const { isConnectionOnline } = useChargePoint();
  const message = useMessage();
  const matches = useMediaQuery('@media (min-width:1600px)');
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [cookie, setCookie] = useCookies(['Authorization']);

  const { pathname } = window.location;

  useEffect(() => {
    if (config.googleMesurementId) {
      ReactGA.dataLayer({
        dataLayer: {
          page: window.location.pathname,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        authorizationParams: {
          audience: config.auth0Audience,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  const setAuthorizationTokenInCookie = async () => {
    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: config.auth0Audience,
          },
        });

        setCookie('Authorization', token);
      }
    } catch (err: any) {
      console.log('myerr', err);
    }
  };

  React.useEffect(() => {
    if (isAuthenticated && !isLoading) {
      setAuthorizationTokenInCookie();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  React.useEffect(() => {
    if (!cookie.Authorization && isAuthenticated && !isLoading) {
      setAuthorizationTokenInCookie();
    }
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <Backdrop
        message={`${!isConnected ? 'Server ' : 'CPMS '} Connection lost. Trying to reconnect ...`}
        open={
          (!isConnected && isAuthenticated) 
          || (!isConnectionOnline && !['/', '/chargers'].includes(pathname) && isAuthenticated)
        }
      />
      <Backdrop
        message="Loading..."
        open={isLoading}
      />
      <CustomDrawer />
      <Snackbar
        open={!!message.text}
        autoHideDuration={3000}
        onClose={() => message.resetMessage()}
      >
        <Alert
          onClose={() => message.resetMessage()}
          severity={message.type}
          sx={{ width: '100%' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
      <Container maxWidth={matches ? 'lg' : 'md'}>
        <Routes />
      </Container>

      <UserFeedback />
    </div>
  );
};

export default App;
