import React from 'react';
import { v4 as uuid } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import { createStoreProvider } from 'utils/context';
import { useMessage } from 'store/message';
import { ws } from 'utils/web-socket';
import { MessageType, MessageTypeEnum } from 'types';

const pathname = window.location.pathname.split('/').reverse();

const chargePointId = pathname[0] !== 'login' 
    && ((pathname[0] === 'logs' || pathname[0] === 'settings')
      ? pathname[1]
      : pathname[0]);

function useWsState() {
  const [wsMessage, setWsMessage] = React.useState<MessageEvent<any>>();
  const [isConnected, setIsConnected] = React.useState(false);

  const { setMessage } = useMessage();
  const { isAuthenticated, isLoading } = useAuth0();

  const sendWSMessage = (messageType: MessageType, payload: any) => {
    ws.send(
      JSON.stringify({
        messageId: uuid(),
        messageType,
        payload,
      }),
    );
  };

  React.useEffect(() => {
    setIsConnected(ws.readyState === 1);
  }, []);

  React.useEffect(() => {
    if (isAuthenticated && !isLoading) {
      console.log(ws.readyState);
      if (ws.readyState === 3) {
        console.log(ws.readyState);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    }
  });

  React.useEffect(() => {
    ws.onopen = (event) => {
      console.log(event);
      setIsConnected(true);

      if (chargePointId) {
        sendWSMessage(MessageTypeEnum.RESET_CLIENT_CONNECTION, {
          chargePointId,
        });
        sendWSMessage(MessageTypeEnum.GET_SETTINGS, { chargePointId });
      }
    };

    ws.onclose = () => {
      setIsConnected(false);
      setMessage({
        text: 'Web Socket Connection closed!',
        type: 'info',
      });
    };

    ws.onerror = () => {
      setMessage({ text: 'Something went wrong!', type: 'error' });
    };

    ws.onmessage = (event) => {
      setWsMessage(event);
    };
  }, [setMessage]);

  return {
    ws,
    wsMessage,
    isConnected,
    sendWSMessage,
  };
}

export const [useWs, WsProvider] = createStoreProvider(useWsState);
