const {
  REACT_APP_WS_ENDPOINT,
  REACT_APP_WS_ENDPOINT_2_0,
  REACT_APP_VERSION,
  REACT_APP_GOOGLE_MEASUREMENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_FEEDBACK_FORM_URL,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_BACKEND_API,
  REACT_APP_BACKEND_API2,
} = process.env;

if (
  !REACT_APP_WS_ENDPOINT
  || !REACT_APP_WS_ENDPOINT_2_0
  || !REACT_APP_VERSION 
  || !REACT_APP_AUTH0_DOMAIN
  || !REACT_APP_AUTH0_CLIENT_ID
  || !REACT_APP_AUTH0_AUDIENCE
  || !REACT_APP_FEEDBACK_FORM_URL
  || !REACT_APP_BACKEND_API
  || !REACT_APP_BACKEND_API2
) {
  throw new Error('Missing Configurations');
}

export const config = {
  wsEndpoint1_6: REACT_APP_WS_ENDPOINT,
  wsEndpoint2_0: REACT_APP_WS_ENDPOINT_2_0,
  appVersion: REACT_APP_VERSION,
  googleMesurementId: REACT_APP_GOOGLE_MEASUREMENT_ID,
  auth0Domain: REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: REACT_APP_AUTH0_CLIENT_ID,
  auth0Audience: REACT_APP_AUTH0_AUDIENCE,
  feedbackFormUrl: REACT_APP_FEEDBACK_FORM_URL,
  backendAPI: REACT_APP_BACKEND_API,
  backendAPI2: REACT_APP_BACKEND_API2,
};
