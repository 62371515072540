import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  onlineOffline: {
    paddingLeft: '5px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fontSize: '30px',
    },
    '& label': {
      marginLeft: '5px',
    },
  },
  debugIconButton: {
    alignSelf: 'flex-end',
    float: 'right',
  },
});

export default useStyles;
