import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  settings: {
    alignSelf: 'flex-end',
    float: 'right',
  },
});

export default useStyles;
