import useStyles from './loading-screen.styles';

const LoadingScreen = () => {

  const classes = useStyles();

  return (
    <div className={classes.loadingScreen}>
      <div className="loader" />
    </div>
  );
};

export default LoadingScreen;

