import React from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';

import { statusColorMap } from 'utils/status-color';

import { ChargePointStatus } from 'types';
import useStyles from './Status.styles';

interface StatusProps {
  chargePointStatus: ChargePointStatus;
}

export const Status: React.FC<StatusProps> = ({ chargePointStatus }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="h6"
      component="label"
      fontWeight="bold"
      className={classes.status}
    >
      Status: &nbsp;
      <Typography
        component="label"
        color={statusColorMap(chargePointStatus)}
        fontWeight="bold"
      >
        {_.upperFirst(chargePointStatus)}
      </Typography>
    </Typography>
  );
};
