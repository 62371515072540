import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  bgDark: {
    background: '#261f62',
  },
  bgLight: {
    background: '#fcfcff',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: 'red',
  },
  component: {
    background: '#fdfcff',
    borderRadius: '5px',
    boxShadow: '10px 10px 15px -10px rgb(227,227,227)',
    border: '1px solid #edebff',
  },
  card: {
    boxShadow: '10px 10px 10px -10px black',
    border: '1px solid black',
  },
});

export default useStyles;
