import React, { useEffect } from 'react';

import { Divider, Paper } from '@mui/material';
import { Modal } from '../Modal/Modal';
import useStyles from './LogsModal.styles';

interface LogsModalProps {
  timestamp: string;
  messageId: string;
  messageType: string;
  log: string;
  isOpenCallBack: (isOpen: boolean) => void;
  callPayload?: string;
  resultPayload?: string;
}

interface ListItemProps {
  name: string;
  value: string;
}

const ListItem: React.FC<ListItemProps> = ({ name, value }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.gridContainer}>
        <div>{name}</div> <div>{value}</div>
      </div>
      <Divider />
    </>
  );
};

interface PayloadItemProps {
  name: string;
  value: string;
}

const PayloadItem: React.FC<PayloadItemProps> = ({ name, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.payloadWrapper}>
      <div>{name}</div>
      <Paper
        elevation={1}
        className={classes.payloadPaper}
      >
        <pre>{JSON.stringify(JSON.parse(value), null, 4)}</pre>
      </Paper>
    </div>
  );
};

const LogsModal: React.FC<LogsModalProps> = ({
  messageType,
  messageId,
  timestamp,
  callPayload,
  resultPayload,
  log,
  isOpenCallBack,
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    isOpenCallBack(open);
  }, [open, isOpenCallBack]);

  return (
    <>
      <div className={classes.modal}>
        <Modal
          open={open}
          setOpen={setOpen}
          title="More details"
          buttonName="More details"
          width="50%"
        >
          <ListItem
            name="Timestamp"
            value={timestamp}
          />
          <ListItem
            name="Message ID"
            value={messageId}
          />
          <ListItem
            name="Message Type"
            value={messageType}
          />
          {callPayload && (
            <PayloadItem
              name="Call Payload"
              value={callPayload}
            />
          )}
          {resultPayload && (
            <PayloadItem
              name="Result Payload"
              value={resultPayload}
            />
          )}
        </Modal>
      </div>
      <div
        onClick={() => setOpen(true)}
        className={classes.logStyle}
      >
        {log}
        <br />
      </div>
      <Divider />
    </>
  );
};

LogsModal.defaultProps = {
  callPayload: '',
  resultPayload: '',
};

export { LogsModal };
