import React from 'react';
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from '@mui/material/styles';
import { ChildrenInterface } from 'types';
import { foundations } from './foundations';
import { components } from './variants';

export const theme = createTheme({
  ...foundations,
  components,
});

export const ThemeProvider: React.FC<ChildrenInterface> = ({ children }) => (
  <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
);
