import { config } from '../../config/config';

function createWebSocket() {
  const ocppVersion = localStorage.getItem('ocppVersion');

  return ocppVersion === 'ocpp2.0.1'
    ? new WebSocket(`${config.wsEndpoint2_0}`)
    : new WebSocket(`${config.wsEndpoint1_6}`);
}

// Initial WebSocket creation let because is mutable
// eslint-disable-next-line
export let ws = createWebSocket();

// Listen for changes in localStorage
window.addEventListener('storage', (event) => {
  const occpVersion = localStorage.getItem('ocppVersion');
  // Check if the relevant keys have changed
  if (
    occpVersion
      && (event.key === 'ocppVersion'
      || event.key === 'chargePointId')
  ) {
    // Recreate the WebSocket when localStorage changes
    console.log('Old socket closed');
    ws.close(); // Close the existing WebSocket

    ws = createWebSocket(); // Create a new WebSocket with updated values
    console.log('Connected to: ', occpVersion);
  }
});
