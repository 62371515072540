import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  MessageText: {
    paddingBottom: '20px',
  },
  ButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    
    '& button:nth-of-type(1)': {
      backgroundColor: '#e10000',
    },

    '& button': {
      width: '30%',
    },
  },
});

export default useStyles;
