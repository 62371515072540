import React from 'react';
import clsx from 'clsx';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@mui/material';

//icons
import EvStationIcon from '@mui/icons-material/EvStation';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppsIcon from '@mui/icons-material/Apps';

import { useNavigate } from 'react-router-dom';
import { getAllChargers, useChargers } from 'store/chargers';
import { config } from '../../config/config';
import useStyles from './Drawer.styles';

export const CustomDrawer = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { logout, user } = useAuth0();
  const { token, setChargers } = useChargers();
  const [, , removeCookie] = useCookies(['Authorization']);

  const handleChargersClick = async () => {
    const chargers = await getAllChargers(token as string, user?.email as string);
    setChargers(chargers);
    navigate('/chargers');
  };

  const itemsList = [
    {
      text: user && (user.nickname || user.email),
      icon: <AccountCircleIcon />,
      onClick: () => {},
    },
    {
      text: 'Connect CP',
      icon: <EvStationIcon />,
      onClick: () => navigate('/'),
    },
    {
      text: 'Chargers',
      icon: <AppsIcon />,
      onClick: () => handleChargersClick(),
    },
    {
      text: 'Sign out',
      icon: <LogoutIcon />,
      onClick: () => {
        logout();
        removeCookie('Authorization');
      },
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: true,
        })}
      >
        <Toolbar>
          <Typography color="white"> Version {config.appVersion} </Typography>{' '}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawer,
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          {itemsList.map((item) => {
            const { text, icon, onClick } = item;
            return (
              <ListItem
                button
                key={text}
                onClick={onClick}
                divider
              >
                <ListItemIcon title={text}>{icon}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </div>
  );
};
