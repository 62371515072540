import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  loadingScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    zIndex: 9999999,
  },
});

export default useStyles;
