import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { Grid, TextField, Typography, Button } from '@mui/material';

import { useWs } from 'store/ws';
import { MessageTypeEnum } from 'types';
import { messageTypes } from '../../constants';
import useStyles from './Settings.styles';

interface SettingsFormInput {
  heartbeatInterval?: number;
  meterValuesInterval?: number;
  chargingRate?: number;
  chargeBoxSerialNumber?: string;
  chargePointModel: string;
  chargePointSerialNumber?: string;
  chargePointVendor: string;
  firmwareVersion?: string;
  iccid?: string;
  imsi?: string;
  meterSerialNumber?: string;
  meterType?: string;
}

type settingsKeys =
  | 'heartbeatInterval'
  | 'meterValuesInterval'
  | 'chargingRate'
  | 'chargeBoxSerialNumber'
  | 'chargePointModel'
  | 'chargePointSerialNumber'
  | 'chargePointVendor'
  | 'firmwareVersion'
  | 'iccid'
  | 'imsi'
  | 'meterSerialNumber'
  | 'meterType';

export const Settings: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm<SettingsFormInput>();
  const { ws, sendWSMessage, wsMessage } = useWs();
  const chargePointId = useParams().id;
  const classes = useStyles();
  const navigate = useNavigate();

  React.useEffect(() => {
    ws.send(
      JSON.stringify({
        messageId: uuid(),
        messageType: messageTypes.GET_SETTINGS,
        payload: {
          chargePointId,
        },
      }),
    );
  }, [chargePointId, ws]);

  React.useEffect(() => {
    if (wsMessage) {
      Object.entries(JSON.parse(wsMessage.data).payload).forEach((d) => {
        setValue(d[0] as settingsKeys, d[1] as string | number);
      });
    }
  }, [setValue, wsMessage]);

  const onSubmit: SubmitHandler<SettingsFormInput> = (data) => {
    sendWSMessage(MessageTypeEnum.UPDATE_SETTINGS, {
      chargePointId,
      data,
    });

    navigate(-1);
  };

  return (
    <>
      <Typography
        variant="h2"
        textAlign="center"
        fontWeight="bold"
      >
        Settings for: {chargePointId}
      </Typography>
      <Grid
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        container
        alignItems="center"
        spacing={4}
        textAlign="center"
        marginTop={2}
      >
        <Grid
          item
          xs={12}
          lg={6}
        >
          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Charge Point Model"
            {...register('chargePointModel', { required: true })}
            required
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Charge Point Vendor"
            {...register('chargePointVendor', { required: true })}
            required
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="number"
            label="Heart Beat Interval"
            {...register('heartbeatInterval', { valueAsNumber: true })}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="number"
            label="MeterValues Interval"
            {...register('meterValuesInterval', { valueAsNumber: true })}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="number"
            label="Charging Rate (Wh)"
            {...register('chargingRate', { valueAsNumber: true })}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Charge Box Serial Number"
            {...register('chargeBoxSerialNumber')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
        >
          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Charge Point Serial Number"
            {...register('chargePointSerialNumber')}
          />

          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Firmware Version"
            {...register('firmwareVersion')}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Iccid"
            {...register('iccid')}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Imsi"
            {...register('imsi')}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Meter Serial Number"
            {...register('meterSerialNumber')}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Meter Type"
            {...register('meterType')}
          />
        </Grid>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="contained"
          size="large"
          className={classes.submitButton}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          className={classes.submitButton}
        >
          Save
        </Button>
      </Grid>
    </>
  );
};
