import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    display: 'grid',
  },
  submitButton: {
    marginTop: '10px !important',
  },
  responsesContainer: {
    backgroundColor: '#f1f1f154',
    borderRadius: '5px',
    height: '32vh',
    maxHeight: '32vh',
    overflowY: 'auto',
    padding: '10px',
  },
  selectContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 40%',
    marginTop: '10px',
  },
  helperTextColor: {
    '& p': {
      color: 'red',
    },
  },
});

export default useStyles;
