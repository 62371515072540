import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';

import { MessageInterface, MessageTypeEnum } from 'types';
import { useLogs } from 'store/logs';
import { useWs } from 'store/ws';
import { useAuth0 } from '@auth0/auth0-react';
import { useChargers } from 'store/chargers';
import { Backdrop } from 'components/backdrop/backdrop';
import chargerImage from '../../assets/charging-station.png';
import connectedChargerImage from '../../assets/charging-station-green.png';
import { useMessage } from '../../store/message';
import useStyles from './Home.styles';

export interface FormInput {
  csmsEndpoint: string;
  debugMode: boolean;
}

export const Home: React.FC = () => {
  const { register, handleSubmit, getValues } = useForm<FormInput>();
  const [isConnected, setIsConnected] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');
  const [ocppVersion, setOcppVersion] = React.useState('');
  const [redirecting, setRedirecting] = React.useState(false);
  const { user } = useAuth0();
  const { chargers } = useChargers();
  const location = useLocation() as any;

  const handleChange = (event: SelectChangeEvent) => {
    localStorage.setItem('ocppVersion', event.target.value as string);

    setOcppVersion(event.target.value as string);
  };

  const { getLogs } = useLogs();
  const { setMessage } = useMessage();
  const classes = useStyles();
  const navigate = useNavigate();
  const { wsMessage, sendWSMessage } = useWs();

  React.useEffect(() => {
    if (location.state) {
      setRedirecting(true);
    }
  }, [location]);

  React.useEffect(() => {
    const handleResponseMessages = (event: MessageEvent<any>) => {
      const { payload } = JSON.parse(event.data) as MessageInterface;
      console.log('payload', payload);
      if (payload.status) {
        if (
          payload.status.includes('Failed')
          || payload.status.includes('fail')
          || payload.status === 'NotConnected'
        ) {
          setMessage({
            type: 'error',
            text: payload.reason || '',
          });
        } else if (payload.status === 'Connected') {
          const data = getValues();

          const chargePointId = data.csmsEndpoint.split('/').pop()?.split('=').pop()
            || localStorage.getItem('chargePointId')
            || undefined;

          console.log('chargePointId', chargePointId);

          if (chargePointId) {
            sendWSMessage(MessageTypeEnum.GET_SETTINGS, {
              chargePointId,
            });
            setIsConnected(true);
            getLogs(chargePointId);
            navigate(
              `/${data.debugMode ? 'debug-charger' : 'charger'
              }/${chargePointId}`,
            );
          }
        }
      }
    };

    if (wsMessage) {
      handleResponseMessages(wsMessage);
    }
  }, [
    setMessage,
    getValues,
    navigate,
    getLogs,
    wsMessage,
    sendWSMessage,
    chargers,
  ]);

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    if (new RegExp('^(wss?:*)').test(getValues().csmsEndpoint)) {
      const chargePointId = data.csmsEndpoint
        .split('/')
        .pop()
        ?.split('=')
        .pop();
      localStorage.setItem('chargePointId', chargePointId || '');

      sendWSMessage(MessageTypeEnum.CONNECT, {
        endpoint: data.csmsEndpoint,
        chargePointId,
        ocppVersion,
        userId: user?.email,
      });
    } else {
      setHelperText('Only Web Socket urls are allowed');
    }
  };

  return redirecting ? (
    <>
      <Backdrop
        message="Connecting..."
        open={redirecting}
      />
    </>
  ) : (
    <>
      <Typography
        variant="h2"
        textAlign="center"
        fontWeight="bold"
      >
        Connect Charge Point
      </Typography>
      <Grid
        container
        alignItems="center"
        spacing={4}
        textAlign="center"
        marginTop={2}
      >
        <Grid
          item
          xs={6}
          lg={6}
        >
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.root}
          >
            <TextField
              value={location?.state?.charger?.endpoint}
              type="url"
              label="CSMS Endpoint"
              className={classes.helperTextColor}
              {...register('csmsEndpoint', { required: true })}
              required
              helperText={helperText}
            />
            <div className={classes.selectContainer}>
              <FormControlLabel
                control={<Switch {...register('debugMode')} />}
                label="Debug Mode"
              />
              <FormControl required>
                <InputLabel id="ocpp-version-label">OCPP version</InputLabel>
                <Select
                  labelId="ocpp-version-label"
                  id="ocpp-version"
                  value={ocppVersion}
                  label="Version"
                  onChange={handleChange}
                >
                  <MenuItem value="ocpp1.6">OCPP 1.6</MenuItem>
                  <MenuItem value="ocpp2.0.1">OCPP 2.0.1</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button
              type="submit"
              variant="contained"
              size="large"
              className={classes.submitButton}
            >
              Connect
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          lg={6}
        >
          <img
            src={isConnected ? connectedChargerImage : chargerImage}
            alt="EV charger"
            width="300px"
          />
        </Grid>
      </Grid>
    </>
  );
};
