import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  Autocomplete,
  Button,
  Checkbox,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { useLogs } from 'store/logs';
import { LogsModal } from '../../components/modal/LogsModal/LogsModal';
import { logsMessageTypes } from '../../types';
import useStyles from './Logs.styles';

export const Logs: React.FC = () => {
  const { logs } = useLogs();
  const [filteredLogs, setFilteredLogs] = React.useState(logs);
  const [filter, setFilter] = React.useState<string[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [lastOpenedModal, setLastOpenedModal] = React.useState('');

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const chargePointId = useParams().id as string;
  const navigate = useNavigate();
  const classes = useStyles();

  React.useEffect(() => {
    if (!isOpen) {
      if (filter.length > 0) {
        setFilteredLogs(
          logs.filter((log) => filter.includes(log.split(' | ')[2])),
        );
      } else {
        setFilteredLogs(logs);
      }
    }
 
  }, [logs, filter, isOpen]);

  const checkIfModalIsOpened = (key: string, open: boolean) => {
    if (open) {
      setLastOpenedModal(key);
      setIsOpen(open);
    }

    if (key === lastOpenedModal && !open) {
      setIsOpen(false);
      setLastOpenedModal('');
    }
  };

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="h2"
        textAlign="center"
        fontWeight="bold"
      >
        Logs for: {chargePointId}
      </Typography>
      <div className={classes.actionsWrapper}>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={logsMessageTypes}
          disableCloseOnSelect
          onChange={(event, value) => {
            setFilter(value);
          }}
          limitTags={5}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              key={option}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          style={{ width: '50%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fileter By Message Types"
              placeholder="Select Message Types"
            />
          )}
        />
        <Button
          variant="contained"
          size="large"
          className={classes.button}
          onClick={() => navigate(-1)}
        >
          Go back
        </Button>
      </div>
      <Paper
        elevation={5}
        className={classes.logs}
      >
        <Typography fontFamily="monospace">
          {filteredLogs.map((log, index) => {
            const [timestamp, callType, messageType, messageId, payload] = log.split(' | ');

            let resultPayload;
            let callPayload;

            if (callType === 'CALL') {
              resultPayload = logs
                .find(
                  (log) => log.includes(messageId) && log.includes('CALL RESULT'),
                )
                ?.split(' | ')[4];

              callPayload = payload;
            } else {
              callPayload = logs
                .find((log) => log.includes(messageId) && !log.includes('CALL RESULT'))
                ?.split(' | ')[4];

              resultPayload = payload;
            }

            return (
              <>
                <LogsModal
                  key={`${index}-${messageId}`}
                  messageType={messageType}
                  messageId={messageId}
                  timestamp={timestamp}
                  callPayload={callPayload}
                  resultPayload={resultPayload}
                  log={log}
                  isOpenCallBack={isOpen => { checkIfModalIsOpened(`${index}-${messageId}`, isOpen); }}
                />
              </>
            );
          })}
        </Typography>
      </Paper>
    </div>
  );
};
