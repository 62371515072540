import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  responsesContainer: {
    backgroundColor: '#eeeeee',
    borderRadius: '5px',
    height: '35vh',
    maxHeight: '35vh',
    overflowY: 'auto',
    padding: '10px',
    marginBottom: '10px',
  },
  image: {
    width: '250px !important',
    '@media (min-height:900px)': {
      width: '300px !important',
    },

    '&:hover': {
      cursor: 'pointer',
      filter: 'opacity(0.5)',
    },
  },
  toolboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default useStyles;
