import React from 'react';
import { Box, Button, Modal as MuiModal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useStyles from './Modal.styles';

interface ModalProps {
  buttonName: string;
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  width?: string;
  disabled?: boolean;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'auto',
  maxHeight: '90vh',
};

const Modal: React.FC<ModalProps> = ({
  children,
  disabled,
  open,
  setOpen,
  title,
  buttonName,
  width,
}) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        size="large"
        disabled={disabled}
      >
        {buttonName}
      </Button>
      <MuiModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ width: width ?? '400px', ...style }}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleClose}
          />
          <Box className={classes.modalBody}>
            {title && (
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                {title}
              </Typography>
            )}
            <Typography
              id="modal-modal-description"
              component="div"
              sx={{ mt: 2 }}
            >
              {children}
            </Typography>
          </Box>
        </Box>
      </MuiModal>
    </>
  );
};

Modal.defaultProps = {
  title: '',
  disabled: false,
  width: '400px',
};

export { Modal };
