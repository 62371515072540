import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from 'App';
import { GlobalProvider } from 'app/GlobalProvider';
import * as serviceWorker from './serviceWorker';
// eslint-disable-next-line react/no-render-return-value
const root = ReactDOM.createRoot(document.getElementById('root')!);

const renderApp = () => root.render(
  <React.StrictMode>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </React.StrictMode>,
);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();

serviceWorker.unregister();
