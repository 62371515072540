import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  feedbackContainer: {
    display: 'none',
    position: 'fixed',
    bottom: 0,
    right: 10,
    background: '#fff',
    height: '90%',
    width: '620px',
    overflow: 'hidden',
    zIndex: 9999999,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.75)',
  },
  header: {
    position: 'absolute',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '50px',
    background: '#261f62',
    zIndex: 8,
  },
  footer: {
    zIndex: 8,
    position: 'absolute',
    width: '100%',
    background: '#fff',
    bottom: 0,
    height: '34px',
  },
  body: {
    overflowY: 'auto',
    padding: '60px 10px 60px 10px',
    height: '100%',
    zIndex: 3,
  },
  frame: {
    position: 'absolute',
    top: 53,
    bottom: 0,
    height: '100%',
    width: '100%',
  },
  close: {
    color: '#fff',
    position: 'absolute',
    right: 10,
    top: 7,
    cursor: 'pointer',
  },
  feedbackBtn: {
    cursor: 'pointer',
    padding: '10px 30px',
    transform: 'rotate(270deg)',
    color: '#fff',
    background: '#1a1544',
    position: 'fixed',
    right: '-50px',
    top: '50%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.75)',
  },
  active: {
    display: 'flex',
  },
});

export default useStyles;
