import {
  Backdrop as MuiBackdrop,
  CircularProgress,
  Typography,
} from '@mui/material';
import React from 'react';

import useStyles from './backdrop.styles';

interface BackdropProps {
  open: boolean;
  message: string;
}

export const Backdrop: React.FC<BackdropProps> = ({ open, message }) => {
  const classes = useStyles();

  return (
    <MuiBackdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <div className={classes.backdropWrapper}>
        <CircularProgress size={60} />
        <Typography>{message}</Typography>
      </div>
    </MuiBackdrop>
  );
};
