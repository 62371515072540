import { useAuth0 } from '@auth0/auth0-react';
import { config } from 'config/config';
import React from 'react';
import { createStoreProvider } from 'utils/context';
import { getRequest } from 'utils/http';

export const getAllChargers = async (token: string, userId: string) => {
  try {
    const response = await getRequest('/chargers', {
      options: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userId,
        },
      },
    }) as any;
    return response.entities;
  } catch (err) {
    console.log(err);
    return [];
  }
};

function useChargersState() {
  const [chargers, setChargers] = React.useState([]);
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = React.useState<string | null>(null);

  React.useEffect(() => {
    const getToken = async () => {
      try {
        const response = await getAccessTokenSilently({
          authorizationParams: { audience: config.auth0Audience },
        });
        setToken(response);
      } catch (err) {
        console.log(err);
      }
    };

    if (!token) {
      getToken();
    }
  }, [token, getAccessTokenSilently, user]);

  return {
    chargers,
    setChargers,
    token,
  };
}

export const [useChargers, ChargersProvider] = createStoreProvider(useChargersState);
