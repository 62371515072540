import React from 'react';
import { createStoreProvider } from 'utils/context';
import { MessageState } from './types';

function useMessageState() {
  const initialState: MessageState = {
    text: '',
    type: 'success',
  };

  const [messageState, setMessageState] = React.useState<MessageState>(initialState);

  return {
    text: messageState.text,
    type: messageState.type,
    setMessage: setMessageState,
    resetMessage: () => setMessageState(initialState),
  };
}

export const [useMessage, MessageProvider] = createStoreProvider(useMessageState);
