export const messageTypes = {
  CONNECT: 'Connect',
  RESET_CLIENT_CONNECTION: 'ResetClientConnection',
  RESET: 'Reset',
  START_CHARGING: 'StartCharging',
  STOP_CHARGING: 'StopCharging',
  START_TRANSACTION: 'StartTransaction',
  START_METER_VALUES: 'StartMeterValues',
  STOP_METER_VALUES: 'StopMeterValues',
  STOP_TRANSACTION: 'StopTransaction',
  UPDATE_SETTINGS: 'UpdateSettings',
  GET_SETTINGS: 'GetSettings',
  GET_LOGS: 'GetLogs',
  GET_CP_INFORMATIONS: 'GetCPInformation',
  GET_STATUS: 'GetStatus',
  GET_CONNECTION_STATUS: 'GetCsmsConnectionStatus',
  SELECT_CONNECTOR: 'SelectConnector',
  PLUG: 'Plug',
  UNPLUG: 'Unplug',
  CLOSE_CSMS_CONNECTION: 'CloseCSMSConnection',
  GET_ALL_CSMS_CONNECTIONS: 'GetAllCSMSConnections',
  LOCK: 'Lock',
  UNLOCK: 'Unlock',
};
