export const MuiCard = {
  styleOverrides: {
    root: {
      borderRadius: '5px',
      backgroundColor: '#d3d3d3d3',
      padding: '5px',
      margin: '5px 0',
    },
  },
};
