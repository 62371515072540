import React from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';

import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';

import useStyles from './OnlineOffline.styles';

interface OnlineOfflineProps {
  isOnline: boolean;
  debug: boolean;
}

export const OnlineOffline: React.FC<OnlineOfflineProps> = ({
  isOnline,
  debug,
}) => {
  const classes = useStyles();

  return debug ? (
    <Tooltip title={isOnline ? 'Online' : 'Offline'}>
      <IconButton className={classes.debugIconButton}>
        {isOnline ? <WifiIcon fontSize="large" /> : <WifiOffIcon fontSize="large" />}
      </IconButton>
    </Tooltip>
  ) : (
    <span className={classes.onlineOffline}>
      {isOnline ? <WifiIcon /> : <WifiOffIcon />}
      <Typography component="label">
        {isOnline ? 'Online' : 'Offline'}
      </Typography>
    </span>
  );
};
