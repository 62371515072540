import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  backdropWrapper: {
    display: 'grid',
    justifyItems: 'center',
    gridGap: '15px',
  },
});

export default useStyles;
