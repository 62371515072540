import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  modalInputs: {
    width: '100%',
    marginBottom: '10px !important',
  },
  modal: {
    '& button:nth-of-type(1)': {
      display: 'none',
    },

    '& div': {
      width: '800px ',
    },
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '30% 60%',
    gridGap: '10px',
    padding: '20px',

    '& div': {
      fontWeight: 'bold',
    },

    '& div:nth-of-type(1)': {
      color: 'darkgray',
    },
  },
  payloadPaper: {
    backgroundColor: '#f3f3f3 !important',
    padding: '20px',
  },
  payloadWrapper: {
    padding: '20px',

    '& div:nth-of-type(1)': {
      fontWeight: 'bold',
      color: 'darkgray',
    },
  },
  jsonWrapper: {
    marginLeft: '30px',
  },
  logStyle: {
    padding: '20px',
    wordBreak: 'break-all',

    '&:hover': {
      cursor: 'pointer',
      opacity: '50%',
    },
  },
});

export default useStyles;
