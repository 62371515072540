import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  modalInputs: {
    width: '100%',
    marginBottom: '10px !important',
  },
});

export default useStyles;
