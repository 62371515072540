import { ChargePointStatus, chargePointStatuses } from '../../types';

export const statusColorMap = (status: ChargePointStatus) => {
  let color = '';

  if (
    status === chargePointStatuses.AVAILABLE
    || status === chargePointStatuses.CHARGING 
    || status === chargePointStatuses.FINISHING
  ) {
    color = 'green';
  } else if (
    status === chargePointStatuses.FAULTED
    || status === chargePointStatuses.UNAVAILABLE
  ) {
    color = 'red';
  } else if (
    status === chargePointStatuses.PREPARING
    || status === chargePointStatuses.RESERVED
  ) {
    color = 'orange';
  }

  return color;
};
