import React from 'react';
import { Button, TextField } from '@mui/material';

import { Modal } from '../Modal/Modal';
import useStyles from './TransactionModal.styles';

interface TransactionModalProps {
  name: string;
  RFIDTag: string;
  messageType: string;
  disabled?: boolean;
  setRFIDTag: (value: React.SetStateAction<string>) => void;
  handleSubmit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const TransactionModal: React.FC<TransactionModalProps> = ({
  name,
  RFIDTag,
  messageType,
  disabled,
  setRFIDTag,
  handleSubmit,
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={name}
      buttonName={name}
      disabled={disabled}
    >
      <TextField
        className={classes.modalInputs}
        type="text"
        label="RFID Tag"
        value={RFIDTag}
        onChange={(event) => setRFIDTag(event.target.value)}
        required
      />
      <Button
        name={messageType}
        variant="contained"
        size="large"
        disabled={!RFIDTag}
        onClick={(event) => {
          setOpen(false);
          handleSubmit(event);
        }}
      >
        {name}
      </Button>
    </Modal>
  );
};

TransactionModal.defaultProps = {
  disabled: false,
};

export { TransactionModal };
