export const MuiToolbar = {
  styleOverrides: {
    regular: {
      minHeight: '32px',
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  },
};
