import React from 'react';
import { MessageInterface, MessagePayloadInterface, MessageTypeEnum } from 'types';
import { createStoreProvider } from 'utils/context';
import { useWs } from 'store/ws';
import { messageTypes } from '../../constants';

function useLogsState() {
  const [logsState, setLogsState] = React.useState(new Set(''));
  const { sendWSMessage, wsMessage } = useWs();

  React.useEffect(() => {
    const handleResponseMessages = (payload: MessagePayloadInterface) => {
      setLogsState((logs) => logs.add(payload.data!));
    };

    if (wsMessage) {
      const { payload, messageType } = JSON.parse(
        wsMessage.data,
      ) as MessageInterface;
      if (
        messageType === messageTypes.GET_LOGS 
      ) {
        handleResponseMessages(payload);
      }
    }
  }, [wsMessage, logsState]);

  const getLogs = (chargePointId: string) => {
    sendWSMessage(MessageTypeEnum.GET_LOGS, {
      chargePointId,
    });
  };

  return {
    logs: Array.from(logsState).reverse().splice(0, 500),
    getLogs,
  };
}

export const [useLogs, LogsProvider] = createStoreProvider(useLogsState);
