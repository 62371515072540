import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';

import useStyles from './Settings.styles';

interface SettingsProps {
  chargePointId: string;
}

export const Settings: React.FC<SettingsProps> = ({ chargePointId }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Tooltip title="Settings">
      <IconButton
        className={classes.settings}
        onClick={() => navigate(`/charger/${chargePointId}/settings`)}
      >
        <SettingsIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};
