import { Connectors } from 'types';

export const INITIAL_CONNECTORS: Connectors = {
  1: {
    connectorId: 0,
    chargePointStatus: 'Available',
    isMeterValueStarted: false,
    isTransactionStarted: false,
    isCharging: false,
    isPlugged: false,
    meterValue: 0,
    isLocked: false,
  },
  2: {
    connectorId: 0,
    chargePointStatus: 'Available',
    isMeterValueStarted: false,
    isTransactionStarted: false,
    isCharging: false,
    isPlugged: false,
    meterValue: 0,
    isLocked: false,
  },
};
