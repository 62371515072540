import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  closeIcon: {
    cursor: 'pointer',
    float: 'right',
    margin: '5px',
    
    '&:hover': {
      color: 'gray',
    },
  },
  modalBody: {
    padding: '20px',
  },
});

export default useStyles;
