import { Edit, EvStation, Wifi, WifiOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useChargers } from 'store/chargers';
import { MessageTypeEnum } from 'types';
import { useWs } from 'store/ws';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import useStyles from './Chargers.style';

export const Chargers: React.FC = () => {
  const classes = useStyles();
  const { chargers } = useChargers();
  const { sendWSMessage } = useWs();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const chargePointId = localStorage.getItem('chargePointId');

  const handleNavigate = (charger: any) => {
    sendWSMessage(MessageTypeEnum.CONNECT, {
      endpoint: charger.endpoint,
      chargePointId: charger.name,
      ocppVersion: charger.ocppVersion,
      userId: user?.email,
    });
    localStorage.setItem('chargePointId', charger.name);
    navigate('/', { state: { redirecting: true } });
  };

  return (
    <>
      <Typography
        variant="h2"
        textAlign="center"
        fontWeight="bold"
        sx={{ marginTop: 3, marginBottom: 3 }}
      >
        Chargers
      </Typography>
      <Grid
        container
        textAlign="center"
        spacing={7}
        alignItems="center"
        marginBottom={6}
      >
        {chargers.map((charger: any, index) => (
          <Grid
            alignItems="center"
            justifyContent="center"
            item
            key={index}
          >
            <Card
              style={{
                boxShadow: '10px 10px 20px -10px #8a8a8a',
                border: '1px solid #261f62',
              }}
              sx={{ padding: 0, maxWidth: 240 }}
            >
              <CardHeader
                style={{ border: '1px solid black' }}
                className={classes.bgDark}
                title={(
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <EvStation sx={{ fontSize: 25, color: '#ffffff' }} />
                    <Typography
                      fontSize={17}
                      sx={{ color: '#ffffff' }}
                    >
                      {charger?.charger?.name}
                    </Typography>
                    <Tooltip
                      placement="bottom-start"
                      title={(
                        <p style={{ fontSize: 11,
                          paddingLeft: 3,
                          paddingRight: 3,
                          letterSpacing: 1,
                          fontWeight: 100,
                        }}
                        >
                          {charger?.charger?.name === chargePointId ? 'Online' : 'Offline'}
                        </p>
                     )}
                      arrow
                    >
                      {charger?.charger?.name === chargePointId
                        ? (
                          <Wifi sx={{ color: '#34c300', fontSize: 25 }} />
                        ) : (
                          <WifiOff sx={{ color: '#ff1f1f', fontSize: 25 }} />
                        )} 
                    </Tooltip>
                  </Box>
                )}
              />
              <CardContent
                sx={{ padding: 2 }}
                className={classes.bgLight}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  gap={2}
                >
                  <Grid
                    className={classes.component}
                    item
                    sx={{ paddingY: 2 }}
                    xs={5}
                    md={5}
                    sm={5}
                    lg={5}
                  >
                    <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#404040' }}>
                      OCPP
                    </Typography>
                    <Typography sx={{ fontSize: 13 }}>
                      {charger?.charger?.ocppVersion.split('ocpp')[1]}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{ paddingY: 2 }}
                    className={classes.component}
                    item
                    xs={5}
                    md={5}
                    sm={5}
                    lg={5}
                  >
                    <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#404040' }}>
                      Model
                    </Typography>
                    <Typography sx={{ fontSize: 13 }}>
                      {charger?.charger?.chargePointModel}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{ paddingY: 2 }}
                    className={classes.component}
                    item
                    xs={5}
                    md={5}
                    sm={5}
                    lg={5}
                  >
                    <Typography
                      className={classes.title}
                      sx={{ fontSize: 14, fontWeight: 600, color: '#404040' }}
                    >
                      Rate
                    </Typography>
                    <Typography sx={{ fontSize: 13 }}>
                      {parseInt(charger?.charger?.chargingRate, 10) / 1000} kW
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{ paddingY: 2 }}
                    className={classes.component}
                    item
                    xs={5}
                    md={5}
                    sm={5}
                    lg={5}
                  >
                    <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                      Vendor
                    </Typography>
                    <Typography sx={{ fontSize: 13 }}>
                      {charger?.charger?.chargePointVendor}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{ paddingTop: 3 }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleNavigate(charger?.charger)}
                  >
                    Connect
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginLeft: 3 }}
                  >
                    <Edit />
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
