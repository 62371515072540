import { makeStyles } from '@mui/styles';
import { theme } from '../../theming';

const drawerWidth = 200;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(3),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'white',
    
    '& div': {
      flexDirection: 'row-reverse',
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    wordBreak: 'break-all',
    width: drawerWidth,
    flexShrink: 0,
    '& div:nth-of-type(1)': {
      boxShadow: '5px 0 5px -6px #333',
    },
  },
});

export default useStyles;
