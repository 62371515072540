import React from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useMessage } from 'store/message';
import { Modal } from '../Modal/Modal';
import useStyles from './DisconnectModal.styles';

interface DisconnectModalProps {
  messageType: string;
  handleSubmit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const DisconnectModal: React.FC<DisconnectModalProps> = ({
  messageType,
  handleSubmit,
}) => {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const { setMessage } = useMessage();

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Disconnect Charger"
      buttonName="Disconnect"
    >
      <Typography className={classes.MessageText}>
        Are you sure you want to disconnect the charger?
      </Typography>
      <div className={classes.ButtonsWrapper}>
        <Button
          name={messageType}
          variant="contained"
          size="large"
          onClick={(event) => {
            setOpen(false);
            handleSubmit(event);
            localStorage.removeItem('chargePointId');
            navigate('/');
            setMessage({
              text: 'Charger successfully disconnected!',
              type: 'success',
            });
          }}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            setOpen(false);
          }}
        >
          No
        </Button>
      </div>
    </Modal>
  );
};

export { DisconnectModal };
