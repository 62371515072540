import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { CookiesProvider } from 'react-cookie';
import { ServiceProvider } from 'services';
import { StoreProvider } from 'store';
import { ThemeProvider } from 'theming';
import { ChildrenInterface } from 'types';
import { useNavigate } from 'react-router-dom';
import { config } from '../../config/config';

const Auth0ProviderWithHistory: React.FC<ChildrenInterface> = ({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate(appState || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      authorizationParams={{ redirect_uri: window.location.origin }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export const GlobalProvider: React.FC<ChildrenInterface> = ({ children }) => (
  <CookiesProvider>
    <ReactRouterDom.BrowserRouter>
      <Auth0ProviderWithHistory>
        <ServiceProvider>
          <StoreProvider>
            <ThemeProvider>{children}</ThemeProvider>
          </StoreProvider>
        </ServiceProvider>
      </Auth0ProviderWithHistory>
    </ReactRouterDom.BrowserRouter>
  </CookiesProvider>
);
