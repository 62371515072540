import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { config } from '../../config/config';
import useStyles from './UserFeedback.styles';
// import useStyles from './UserFeedback.styles';

const UserFeedback: React.FC<any> = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const url = config.feedbackFormUrl;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOpen = () => {
    if (windowDimensions.width < 650) {
      if (window) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.open(url, '_blank').focus();
      }
    } else {
      setOpen(true);
    }
  };

  const containerClasses = [classes.feedbackContainer];
  if (open) {
    containerClasses.push(classes.active);
  }

  return (
    <>
      {
          !url || url.length < 1 ? ''

            : (
              <div
                className={containerClasses.join(' ')}
              >
                <div className={classes.header}>
                  <img
                    src="/mg-logo-white.png"
                    height={20}
                    width="auto"
                  />
                  <span
                    onClick={() => setOpen(false)}
                    className={classes.close}
                  >
                    <CloseIcon
                      className={classes.close}
                    />
                  </span>
                </div>

                <div
                  className={classes.body}
                >
                  <iframe
                    className={classes.frame}
                    src={url}
                    frameBorder={0}
                    marginHeight={0}
                    marginWidth={0}
                  >Loading…
                  </iframe>
                </div>

                <div className={classes.footer} />
              </div>
            )
      }
      {
        open || (!url || url.length < 1) ? ''
          : (
            <div
              onClick={handleOpen}
              className={classes.feedbackBtn}
            >
              Feedback
            </div>
          )
      }

    </>
  );
};

export default UserFeedback;
