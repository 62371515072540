import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'grid',
    position: 'absolute',
    width: '80%',
    left: '16.5%',
  },
  logs: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2vh',
    height: '70vh',
    maxHeight: '90vh',
    padding: '1vh',
    overflow: 'auto',
  },
  button: {
    marginTop: '2vh !important',
    width: '25%',
    alignSelf: 'center',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default useStyles;
