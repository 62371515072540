import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import * as Pages from 'pages';
import { useAuth0 } from '@auth0/auth0-react';
import { Routing } from 'global/routing';

export const Routes: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <ReactRouterDom.Routes>
      <ReactRouterDom.Route
        path={Routing.HOME.getPath()}
        element={<Pages.Home />}
      />

      <ReactRouterDom.Route
        path={Routing.CHARGER_PAGE.getPath({ id: ':id' })}
        element={<Pages.ChargePointPage />}
      />

      <ReactRouterDom.Route
        path={Routing.DEBUG_CHARGER_PAGE.getPath({ id: ':id' })}
        element={<Pages.ChargePointDebugPage />}
      />

      <ReactRouterDom.Route
        path={Routing.SETTINGS.getPath({ id: ':id' })}
        element={<Pages.Settings />}
      />

      <ReactRouterDom.Route
        path={Routing.LOGS.getPath({ id: ':id' })}
        element={<Pages.Logs />}
      />

      <ReactRouterDom.Route
        path={Routing.LOGIN.getPath()}
        element={<Pages.Login />}
      />

      <ReactRouterDom.Route
        path={Routing.CHARGERS.getPath()}
        element={<Pages.Chargers />}
      />

      <ReactRouterDom.Route
        path="*"
        element={<ReactRouterDom.Navigate to="/" />}
      />
    </ReactRouterDom.Routes>
  ) : (
    <ReactRouterDom.Routes>
      <ReactRouterDom.Route
        path={Routing.HOME.getPath()}
        element={<Pages.Home />}
      />
      <ReactRouterDom.Route
        path="*"
        element={<ReactRouterDom.Navigate to="/" />}
      />
    </ReactRouterDom.Routes>
  );
};
